export const useBreakpoint = () => {
  const screenWidth = ref(0);

  const getScreenBreakpoint = computed(() => {
    if (screenWidth.value < 768) return 'sm';
    else if (screenWidth.value >= 768 && screenWidth.value < 1280) return 'md';
    else if (screenWidth.value >= 1280 && screenWidth.value < 1536) return 'xl';
    else if (screenWidth.value >= 1536) return '2xl';
  });

  const isSmallDesktop = computed(() => {
    return screenWidth.value >= 1280 && screenWidth.value < 1536;
  });
  const isDesktop = computed(() => {
    return screenWidth.value >= 1280;
  });
  const isNotDesktop = computed(() => {
    return screenWidth.value < 1280;
  });
  const isPad = computed(() => {
    return screenWidth.value >= 768 && screenWidth.value < 1280;
  });
  const isNotPad = computed(() => {
    return !(screenWidth.value >= 768 && screenWidth.value < 1280);
  });
  const isVerticalPad = computed(() => {
    return screenWidth.value >= 768 && screenWidth.value < 1024;
  });
  const isHorizontalPad = computed(() => {
    return screenWidth.value >= 1024 && screenWidth.value < 1280;
  });
  const isHorizontalPadOrMore = computed(() => {
    return screenWidth.value >= 1024;
  });
  const isLessThanHorizontalPad = computed(() => {
    return screenWidth.value < 1024;
  });
  const isMobile = computed(() => {
    return screenWidth.value < 768;
  });
  const isNotMobile = computed(() => {
    return screenWidth.value >= 768;
  });

  const handleResize = () => {
    screenWidth.value = window.innerWidth;
  };

  onMounted(() => {
    screenWidth.value = window.innerWidth;
    addEventListener('resize', handleResize);
  });

  onBeforeUnmount(() => {
    removeEventListener('resize', handleResize);
  });

  return {
    getScreenBreakpoint,
    isSmallDesktop,
    isDesktop,
    isNotDesktop,
    isPad,
    isNotPad,
    isVerticalPad,
    isHorizontalPad,
    isHorizontalPadOrMore,
    isLessThanHorizontalPad,
    isMobile,
    isNotMobile,
    handleResize,
  };
};
